<template>
    <!-- Off-canvas menu -->
    <div class="offcanvas offcanvas-end" tabindex="-1" id="settingsMenu" aria-labelledby="settingsMenuLabel">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="settingsMenuLabel">Character Sheet Settings</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <button @click="goToHome" class="btn btn-primary">Back to Cast List</button>
            <ul class="list-group">
                <li class="list-group-item">Item 1</li>
                <li class="list-group-item">Item 2</li>
                <li class="list-group-item">Item 3</li>
            </ul>
        </div>
    </div>
</template>

<script>
import { Offcanvas } from 'bootstrap';

export default {
    methods: {
        goToHome() {
            this.closeSettingsMenu();
            this.$router.push({ name: 'Home' });
        },
        closeSettingsMenu() {
            // Ensure the offcanvas element is available
            const offcanvasElement = document.getElementById('settingsMenu');
            if (offcanvasElement) {
                // Use Bootstrap's Offcanvas component directly
                const bsOffcanvas = Offcanvas.getInstance(offcanvasElement);
                if (bsOffcanvas) {
                    bsOffcanvas.hide();
                    offcanvasElement.addEventListener('hidden.bs.offcanvas', () => {
                    // Remove the backdrop by targeting the specific class
                    const backdrop = document.querySelector('.offcanvas-backdrop');
                    if (backdrop) {
                        backdrop.remove();
                    }
                });
                } else {
                    console.error('Bootstrap Offcanvas instance not found');
                }
            } else {
                console.error('Offcanvas element not found');
            }
        }
    }
}
</script>

<style>

</style>