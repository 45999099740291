<template>
    <div class="bg-dark vh-100">
        <router-view/>
    </div>
</template>

<script>
export default {
    name: 'App',
}
</script>

<style>

</style>
