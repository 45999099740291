<template>
    <nav class="navbar navbar-expand-lg bg-dark" data-bs-theme="dark">
        <div class="container-fluid">
            <a class="navbar-brand" href="#" style="position: absolute; left: 50%; transform: translateX(-50%);">
                <img src="@/assets/shiver.svg" alt="Shiver Logo" class="pt-1" style="height: 4rem" /> Cast List
            </a>
            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ms-auto">
                    
                    <li class="nav-item">
                        <!-- Button to open the modal -->
                        <button
                            class="btn btn-primary ms-2"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#settingsMenu"
                            aria-controls="settingsMenu"
                        >
                            <i class="ra ra-cog"></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- Include the NewCharacterModal.vue component -->
    <new-character-modal1 @save="saveCharacter"></new-character-modal1>
    <settings />
</template>

<script>
import NewCharacterModal1 from "./NewCharacterModal1.vue";
import Settings from "./Settings.vue";

export default {
    components: {
        NewCharacterModal1,
        Settings
    },
};
</script>

<style>
/* Add your custom styles here if needed */
</style>
