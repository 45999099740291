<template>
    <top-bar/>
    <div v-if="character" class="character-sheet">
        <h2>{{ character.name }}</h2>
        <!-- Display more character details here -->
    </div>
    <div v-else>
        <p>Character not found.</p>
    </div>
</template>

<script>
import TopBar from './TopBar.vue';
import { ref, watch } from 'vue';
import { useRosterStore } from '../stores/index'; // Assuming you have a Vuex store

export default {
    components: { 
        TopBar
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    setup(props) {
        console.log("id=" + props.id);
        const store = useRosterStore();
        const character = ref(null);

        const fetchCharacter = async () => {
            try {
                // Assuming `getCharacterById` is a method in your store that returns a character by ID
                character.value = await store.getCharacterById(props.id);
                console.log("Character fetched", character.value);
            } catch (error) {
                console.error("Failed to fetch character", error);
            }
        };

        // Fetch character when component is mounted
        fetchCharacter();

        // Re-fetch character if route parameter changes
        watch(() => props.id, fetchCharacter);

        return {
            character,
        };
    },
};
</script>

<style scoped>
.character-sheet {
    color: white;
    font-size: 24px;
    padding: 20px;
}
</style>