<template>
        <roster-bar/>
    <div class="container"> 
        <div class="row">
            <div class="col-sm-12 text-center">Shiver Log Here</div>
            <div class="col-sm-12">
                <h1 class="text-center text-white">Roster</h1>
            </div>
            <div v-if="characters.length > 0">
                <div class="col-sm-3 mb-3" v-for="(character, index) in characters" :key="index">
                    <character-box :character="character" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CharacterBox from './CharacterBox.vue'
import { computed } from 'vue';
import { useRosterStore } from '../stores/index';
import RosterBar from './RosterBar.vue';

export default {
    
    setup() {
        const store = useRosterStore();
        const characters = computed(() => store.characters);
  
        return {
            characters
        };
    },
    components: { 
        CharacterBox,
        RosterBar
    },
}
</script>