<template>
    <div
        class="modal fade"
        id="newCharacterModal"
        tabindex="-1"
        aria-labelledby="newCharacterModalLabel"
        aria-hidden="true"
        ref="newCharacterModal"
    >
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content bg-dark text-light">
                <div class="modal-header">
                    <h5 class="modal-title" id="newCharacterModalLabel">
                        New Character
                    </h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-tabs">
                        <li class="nav-item">
                            <a 
                                class="nav-link active" 
                                data-bs-toggle="tab" 
                                href="#archetype"
                                ref="archetypeTab"
                            >
                                Archetype
                            </a>
                        </li>
                        <li class="nav-item">
                            <a
                                class="nav-link"
                                :class="{ disabled: !isArchetypeAndBackgroundSelected }"
                                data-bs-toggle="tab"
                                href="#skills"
                            >
                                Skills & Talents
                            </a>
                        </li>
                        <li class="nav-item">
                            <a 
                                class="nav-link" 
                                :class="{ disabled: !showFinalTab }"
                                data-bs-toggle="tab" 
                                href="#details">
                                Final Details
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div id="archetype" class="container-fluid tab-pane active"><br>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="input-group mb-3">
                                        <label class="input-group-text" for="archetypeSelect">Archetype</label>
                                        <select class="form-select" id="archetypeSelect" @change="updateCharacterArchetype">
                                            <option value="" disabled selected>Please select...</option>
                                            <option v-for="archetype in archetypes" :key="archetype.archetype" :value="archetype.archetype">
                                                {{ archetype.archetype }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="input-group mb-3">
                                        <label class="input-group-text" for="backgroundSelect">Background</label>
                                        <select class="form-select" id="backgroundSelect" @change="updateCharacterBackground">
                                            <option value="" disabled selected>Please select...</option>
                                            <optgroup v-for="(backgrounds, archetype) in groupedBackgrounds" :key="archetype" :label="archetype">
                                                <option v-for="background in backgrounds" :key="background.background" :value="background.background">
                                                    {{ background.background }}
                                                </option>
                                            </optgroup>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div v-if="newCharacterArchetype" class="row">
                                        <div class="col-sm-12">
                                            <h5>Selected Archetype Details</h5>
                                        </div>
                                        <div class="col-sm-12 mb-2">
                                            <strong>Archetype:</strong> {{ newCharacterArchetype.archetype }}
                                        </div>
                                        <div class="col-sm-12 mb-2">
                                            <strong>Description:</strong> {{ newCharacterArchetype.description }}
                                        </div>
                                        <!-- Add more fields as needed -->
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div v-if="newCharacterArchetype && newCharacterArchetype.background" class="row">
                                        <div class="col-sm-12 mb-2">
                                            <h5>Selected Background Details</h5>
                                        </div>
                                        <div class="col-sm-6 mb-2">
                                            <strong>Background:</strong> {{ newCharacterArchetype.background.background }}
                                        </div>
                                        <div class="col-sm-6 mb-2">
                                            <strong>Archetype</strong> {{ newCharacterArchetype.background.archetype }}
                                        </div>
                                        <div class="col-sm-12 mb-2">
                                            <strong>Description:</strong> {{ newCharacterArchetype.background.description }}
                                        </div>
                                        <div class="col-sm-6 mb-2">
                                            <strong>Ability:</strong> {{ newCharacterArchetype.background.ability }}
                                            <p> {{ newCharacterArchetype.background.abilityDescription }}</p>
                                        </div>
                                        <div class="col-sm-6 mb-2">
                                            <strong>Flaw:</strong> {{ newCharacterArchetype.background.flaw }}
                                            <p> {{ newCharacterArchetype.background.flawDescription }}</p>
                                        </div>
                                        <!-- Add more fields as needed -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="skills" class="container-fluid tab-pane fade"><br>
                            <div class="row"  v-if="newCharacterArchetype">
                                <div class="col-sm-12">
                                    <h2>Skills & Talents</h2>
                                </div>
                                <div class="col-sm text-center">
                                    <h3>Grit</h3>
                                    <p>{{ newCharacterArchetype.stats.grit.skill }}</p>
                                    <p>{{ newCharacterArchetype.stats.grit.talent }}</p>
                                </div>
                                <div class="col-sm text-center">
                                    <h3>Wit</h3>
                                    <p>{{ newCharacterArchetype.stats.wit.skill }}</p>
                                    <p>{{ newCharacterArchetype.stats.wit.talent }}</p>
                                </div>
                                <div class="col-sm text-center">
                                    <h3>Smarts</h3>
                                    <p>{{ newCharacterArchetype.stats.smarts.skill }}</p>
                                    <p>{{ newCharacterArchetype.stats.smarts.talent }}</p>
                                </div>
                                <div class="col-sm text-center">
                                    <h3>Heart</h3>
                                    <p>{{ newCharacterArchetype.stats.heart.skill }}</p>
                                    <p>{{ newCharacterArchetype.stats.heart.talent }}</p>
                                </div>
                                <div class="col-sm text-center">
                                    <h3>Luck</h3>
                                    <p>{{ newCharacterArchetype.stats.luck.skill }}</p>
                                    <p>{{ newCharacterArchetype.stats.luck.talent }}</p>
                                </div>
                                <div class="col-sm text-center">
                                    <h3>Strange</h3>
                                    <p>{{ newCharacterArchetype.stats.strange.skill }}</p>
                                    <p>{{ newCharacterArchetype.stats.strange.talent }}</p>
                                </div>
                                <div class="col-sm-12">
                                    <hr />
                                </div>
                                
                                <div class="col-sm-6 text center" v-if="newCharacterArchetype.core != null">
                                    <h3>Strength</h3>
                                    <h4>{{ skillList[newCharacterArchetype.core].name }}</h4>
                                </div>
                                <div class="col-sm-6" v-if="newCharacterArchetype.core == null">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h3>Strengths</h3>
                                        </div>
                                        <div class="col-sm-6">
                                            <h4>Talent</h4>
                                            <select class="form-select" @change="handleAttributeChange($event, 'talent', 'talent', newCharacterArchetype.strengths.talent)">
                                                <option disabled selected>Please select...</option>
                                                <option v-for="skill in skillList" :key="skill.value" :value="skill.name" :disabled="isDisabled(skill.name)">{{ capitalize(skill.name) }}</option>
                                            </select>                                
                                        </div>
                                        <div class="col-sm-6">
                                            <h4>Skill</h4>
                                            <select class="form-select" @change="handleAttributeChange($event, 'skill', 'skill', newCharacterArchetype.strengths.skill)">
                                                <option disabled selected>Please select...</option>
                                                <option v-for="skill in skillList" :key="skill.value" :value="skill.name" :disabled="isDisabled(skill.name)">{{ capitalize(skill.name) }}</option>
                                            </select>                                
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <h3>Deficiency</h3>
                                        </div>
                                        <div class="col-sm-6" v-for="(value, key) in newCharacterArchetype.deficiency" :key="key">
                                            <h4>Skill {{ newCharacterArchetype.deficiency[key] }}</h4>
                                            <select class="form-select" @change="handleAttributeChange($event, 'skill', key, value)">
                                                <option disabled selected>Please select...</option>
                                                <option v-for="skill in skillList" :key="skill.value" :value="skill.name" :disabled="isDisabled(skill.name)">{{ capitalize(skill.name) }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="details" class="container-fluid tab-pane fade"><br>
                            <div class="row" v-if="newCharacterArchetype">
                                <div class="col-sm-12">
                                    <h2>Final Details</h2>
                                </div>
                                <div class="col-sm-6">
                                    <div class="input-group mb-3">
                                        <label class="input-group-text" for="nameInput">Name</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="nameInput"
                                            v-model="newCharacterArchetype.name"
                                        />
                                    </div>
                                    <div class="input-group mb-3">
                                        <label class="input-group-text" for="ageInput">Age</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="ageInput"
                                            v-model="newCharacterArchetype.age"
                                        />
                                    </div>
                                    <div class="input-group mb-3">
                                        <label class="input-group-text" for="genderInput">Gender</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="genderInput"
                                            v-model="newCharacterArchetype.gender"
                                        />
                                    </div>
                                    <div class="input-group mb-3">
                                        <label class="input-group-text" for="fearInput">Fear</label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            id="fearInput"
                                            v-model="newCharacterArchetype.fear"
                                        />
                                    </div>
                                    <div class="mb3">
                                        <label for="backstoryInput" class="form-label">Backstory</label>
                                        <textarea
                                            class="form-control"
                                            id="backstoryInput"
                                            rows="6"
                                            v-model="newCharacterArchetype.backstory"
                                        ></textarea>
                                    </div>
                                    <div class="mb-3">
                                        <label for="formFile" class="form-label">Headshot</label>
                                        <input class="form-control" type="file" id="avatarInput" @change="addAvatar">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="text-center">
                                        <img  
                                            v-if="newCharacterArchetype"
                                            :src="newCharacterArchetype.avatar" 
                                            alt="Avatar" 
                                            class="img-fluid" 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="addCharacter"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { computed, ref, nextTick } from "vue";
import { useRosterStore } from "../stores/index"; // Adjust the import path as necessary
import { Tab } from 'bootstrap';

export default {
    data() {
        return {
            archetypes: [],
            backgrounds: [],
            newCharacterArchetype: null,
            skillList: {
                grit: { value: 0, name: "grit", setBy: null, adjustmentType: null, adjustment: 0 },
                wit:{ value: 1, name: "wit", setBy: null, adjustmentType: null, adjustment: 0 },
                smarts:{ value: 2, name: "smarts", setBy: null, adjustmentType: null, adjustment: 0 },
                heart:{ value: 3, name: "heart", setBy: null, adjustmentType: null, adjustment: 0 },
                luck:{ value: 4, name: "luck", setBy: null, adjustmentType: null, adjustment: 0 },
                strange:{ value: 5, name: "strange", setBy: null, adjustmentType: null, adjustment: 0 },
            },
            showFinalTab: false,
            showSkillTab: false
        };
    },
    methods: {
        addAvatar(event) {
            const file = event.target.files[0];
            if (file && file.type.startsWith('image/')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const img = new Image();
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        let width = img.width;
                        let height = img.height;

                        // Calculate the new dimensions while maintaining the aspect ratio
                        if (width > 1000 || height > 1000) {
                            if (width > height) {
                                height = Math.round((height *= 1000 / width));
                                width = 1000;
                            } else {
                                width = Math.round((width *= 1000 / height));
                                height = 1000;
                            }
                        }

                        canvas.width = width;
                        canvas.height = height;
                        ctx.drawImage(img, 0, 0, width, height);

                        const base64String = canvas.toDataURL(file.type);
                        this.newCharacterArchetype.avatar = base64String;
                    };
                    img.src = e.target.result;
                };
                reader.readAsDataURL(file);
            }
        },
        allSelectsFilled() {
            const selects = document.querySelectorAll('#skills select');
            const displayedSelects = Array.from(selects).filter(select => select.offsetParent !== null);
            if (displayedSelects.length === 0) {
                return
            }
            const allFilled = displayedSelects.every(select => select.value && select.value !== 'Please select...');
            if(allFilled) {
                this.showFinalTab = true;
            }

        },
        capitalize(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        resetModalData() {
            this.resetAttributeChange();
            this.updateSelectVisibility();
            const archetypeTab = this.$refs.archetypeTab;
            const tab = new Tab(archetypeTab);
            tab.show();
            const archetypeSelect = document.getElementById('archetypeSelect');
            const backgroundSelect = document.getElementById('backgroundSelect');
            const avatarInput = document.getElementById('avatarInput');
            if (avatarInput) avatarInput.value = "";
            if (archetypeSelect) archetypeSelect.selectedIndex = 0;
            if (backgroundSelect) backgroundSelect.selectedIndex = 0;
            this.newCharacterArchetype = null
            this.showFinalTab = false;
            this.showSkillTab = false;
        },
        updateCharacterArchetype(event) {
            const selectedArchetypeName = event.target.value;
            const selectedArchetype = this.archetypes.find(archetype => archetype.archetype === selectedArchetypeName);
            this.newCharacterArchetype = JSON.parse(JSON.stringify(selectedArchetype));
            this.newCharacterArchetype.name = ""
            this.newCharacterArchetype.age = ""
            this.newCharacterArchetype.gender = ""
            this.newCharacterArchetype.fear = ""
            this.newCharacterArchetype.backstory = ""
        },
        updateCharacterBackground(event) {
            const selectedBackgroundName = event.target.value;
            const selectedBackground = this.backgrounds.find(background => background.background === selectedBackgroundName);
            this.newCharacterArchetype.background = JSON.parse(JSON.stringify(selectedBackground));
        },
        updateSelectVisibility() {
            // Update the selectVisibility array to trigger reactivity
            this.selectVisibility = Array.from(document.querySelectorAll('#skills select')).map(select => select.offsetParent !== null);
        },
        clearAttributeChange(setBy, type) {
            Object.keys(this.skillList).forEach(key => {
                const skill = this.skillList[key];
                if (skill.setBy === setBy) {
                    this.newCharacterArchetype.stats[key][type] -= skill.adjustment;
                    skill.setBy = null;
                    skill.adjustmentType = null;
                    skill.adjustment = 0;
                }
            });
        },
        resetAttributeChange() {
            Object.keys(this.skillList).forEach(key => {
                this.skillList[key].setBy = null;
                this.skillList[key].adjustmentType = null;
                this.skillList[key].adjustment = 0;
            });
        },
        handleAttributeChange(event, type, setBy, value) {
            this.clearAttributeChange(setBy, type)
            this.newCharacterArchetype.stats[event.target.value][type] += value;
            this.skillList[event.target.value].setBy = setBy;
            this.skillList[event.target.value].adjustmentType = type;
            this.skillList[event.target.value].adjustment = value;
            this.allSelectsFilled();
        },
        isDisabled(skillValue) {
            if(this.skillList[skillValue].setBy !== null) {
                return true;
            }else{
                return false;
            }
        }
    },
    created() {
        this.archetypes = require('@/assets/archetypes.json');
        this.backgrounds = require('@/assets/backgrounds.json');
    },
    mounted() {
        // Initial update of select visibility
        this.updateSelectVisibility();

        // Add event listener for modal close
        nextTick(() => {
            const modalElement = this.$refs.newCharacterModal;
            if (modalElement) {
                modalElement.addEventListener('hidden.bs.modal', this.resetModalData);
            }
        });
    },
    setup() {
        const store = useRosterStore();
        const characters = computed(() => store.characters);

        const addCharacter = () => {
            const maxId = store.characters.reduce((max, character) => Math.max(max, character.id), 0);
            const newCharacter = {
                id: maxId + 1, // Increment the maximum found ID by 1
                // Add other character properties here, including the selected archetype
            };
            // Logic to add the new character to the store
        };

        // Add method to handle selection of an archetype and immediately create a copy
        const selectArchetype = (archetype) => {
            // Assuming deep copy is necessary to avoid mutating the original object
            this.newCharacterArchetype = JSON.parse(JSON.stringify(archetype));
        };

        const selectBackground = (background) => {
            // Assuming deep copy is necessary to avoid mutating the original object
            this.newCharacterBackground = JSON.parse(JSON.stringify(background));
        };

        return { characters, addCharacter, selectArchetype, selectBackground };
    },
    computed: {
        groupedBackgrounds() {
            const groups = {};
            this.backgrounds.forEach((background) => {
                if (!groups[background.archetype]) {
                    groups[background.archetype] = [];
                }
                groups[background.archetype].push(background);
            });
            return groups;
        },
        isArchetypeAndBackgroundSelected() {
            return this.newCharacterArchetype && this.newCharacterArchetype.background;
        }
    },
    watch: {
        newCharacterArchetype(newVal) {
            this.resetAttributeChange();
            if (newVal && newVal.core != null) {
                this.skillList[this.newCharacterArchetype.core].setBy = 'core';
                this.skillList[this.newCharacterArchetype.core].adjustmentType = 'core';
                this.skillList[this.newCharacterArchetype.core].adjustment = 0;
            }
        }
    }
};
</script>

<style>
</style>
