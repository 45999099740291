<template>
    <div class="card">
        <img :src="characterImage" class="card-img-top" alt="Character Image">
        <div class="card-body text-center">
            <h5 class="card-title">{{ character.name }}</h5>
            <p class="card-text">{{ characterDescription }}</p>
        </div>
        <div class="card-footer d-flex">
            <router-link :to="{ name: 'Character', params: { id: character.id } }" class="btn btn-primary w-50 no-round btm-left-round">Open</router-link>
            <button @click="deleteCharacter(character.id)" class="btn btn-danger w-50 no-round btm-right-round">Delete</button>
        </div>
    </div>
</template>

<script>
import defaultAvatar from '@/assets/avatar.jpg';
import { useRosterStore } from '@/stores/index'; // Adjust the path as necessary

export default {
    props: {
        character: {
            type: Object,
            required: true
        }
    },
    methods: {
        deleteCharacter(id) {
            if (window.confirm("Are you sure you want to delete this character?")) {
                const rosterStore = useRosterStore(); // Corrected to use the imported store
                rosterStore.removeCharacter(id);
            }
        }
    },
    computed: {
        characterDescription() {
            console.log(this.character)
            return this.character.description || 'Description here';
        },
        characterImage() {
            return this.character.image || defaultAvatar;
        }
    }
}
</script>

<style>
.card{
    border-color: #000 !important;
}

.card-footer{
    padding: 0 !important;
}

.no-round {
    border-radius: 0 !important; /* Override any existing border-radius */
}

.btm-left-round {
    border-bottom-left-radius: 5px !important; /* Override any existing border-radius */
}

.btm-right-round {
    border-bottom-right-radius: 5px !important; /* Override any existing border-radius */
}   
</style>