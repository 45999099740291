<template>
    <!-- Off-canvas menu -->
    <div class="offcanvas offcanvas-end" tabindex="-1" id="compendiumMenu" aria-labelledby="compendiumMenuLabel">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="compendiumMenuLabel">Manage Compendium</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <ul class="list-group">
                <li class="list-group-item">Item 1</li>
                <li class="list-group-item">Item 2</li>
                <li class="list-group-item">Item 3</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style>

</style>