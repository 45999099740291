import { defineStore } from 'pinia';
import piniaPersistedState from 'pinia-plugin-persistedstate';

export const useRosterStore = defineStore('roster', {
  state: () => ({
    // Define your initial state
    characters: [],
  }),
  actions: {
    // Action to add a new character
    addCharacter(character) {
      this.characters.push(character);
    },
    // Action to remove a character by index
    removeCharacter(characterId) {
      this.characters = this.characters.filter(c => c.id !== characterId);
    },
    // Action to update a character by index
    updateCharacter(index, updatedCharacter) {
      this.characters[index] = updatedCharacter;
    },
  },
  getters: {
    getCharacterById: (state) => (id) => {
      console.log("Searching for ID:", id); // Log the ID being searched for
      console.log("Current characters in state:", state.characters); // Log the characters array
    
      const numericId = Number(id); // Convert id to a number
    
      const foundCharacter = state.characters.find(character => {
        console.log("Checking character with ID:", character.id); // Log each character's ID during the search
        return character.id === numericId; // Compare with numeric ID
      });
    
      if (!foundCharacter) {
        console.log("Character not found for ID:", id); // Log if not found
      } else {
        console.log("Character found:", foundCharacter); // Log the found character
      }
    
      return foundCharacter;
    },
  },
  persist: {
    enabled: true,
  },
});
