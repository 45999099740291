import { createRouter, createWebHistory } from 'vue-router';
import Roster from '../components/Roster.vue';
import CharacterSheet from '../components/CharacterSheet.vue';

const routes = [
    { 
        path: '/', 
        name: 'Home', 
        component: Roster 
    },
    { 
        path: '/character/:id', 
        name: 'Character', 
        component: CharacterSheet, 
        props: true 
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
  
export default router;